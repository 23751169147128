@import './public/assets/scss/media-mixins';

.UnverifiedAccountError {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  &__title {
    font-family: 'RobotoRegular', sans-serif;
    font-weight: 400;
    font-size: 28px;
    line-height: 33px;
    margin-top: 40px;
    white-space: pre-line;
  }

  &__description {
    font-family: 'RobotoLight', sans-serif;
    font-weight: 300;
    font-size: 20px;
    line-height: 28px;
    margin-top: 30px;
    margin-bottom: 40px;
    white-space: pre-line;
  }

  .Button {
    width: 240px;
  }

  @include media-screen-md {

    &__title {
      font-size: 2.0rem;
      line-height: 2.6rem;
    }

    &__description {
      font-size: 1.4rem;
      line-height: 2rem;
      margin: 2.4rem auto 2.7rem;
    }
  }
}
