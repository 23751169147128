.ContentLoader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #231F20;

    &__icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 68px;
            height: 66px;
        }
    }

    &__spin {
        width: 160px;
        height: 160px;
        animation: spin 1s infinite;

        img {
            width: 100%;
            height: 100%;
        }

        @keyframes spin {
            0% {
                transform: rotate(360deg);
            }
            100% {
                transform: rotate(0deg);
            }
        }
    }
}
