@import './public/assets/scss/media-mixins';

.CastingStatusConnected {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__illustration {
    height: 160px;
    margin-bottom: 23px;
  }

  &__description {
    font-family: 'RobotoLight', sans-serif;
    font-weight: 400;
    font-size: 3.6rem;
    line-height: 4.2rem;
    text-align: center;
    color: #231F20;
    margin-bottom: 23px;
  }

  .Button {
    display: flex;
    justify-content: center;

    img {
      margin-right: 10px;
      width: 29px;
      height: 24px;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px 30px;
      width: auto;
      height: auto;
      text-transform: none;

      &:hover {
        background: #45DD97;
        border-color: #45DD97;
      }
    }
  }

  @include media-screen-md {
    &__description {
      font-size: 2rem;
      line-height: 2.6rem;
      margin-bottom: 32px;
    }

    .Button {
      button span {
        font-size: 1.4rem;
        line-height: 1.6rem;
        color: #FFFFFF;
      }
    }
  }
}
