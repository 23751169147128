@import './public/assets/scss/media-mixins';

.Casting {
  //height: 100%;
  flex-grow: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  &__container {
    position: relative;
    width: 80%;
    height: 80%;
    object-fit: cover;
    box-shadow: 0 4px 9px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    text-align: center;

    &.isIFrame {
      width: 100%;
      height: 100%;
    }
  }

  &__fullscreen-button {
    z-index: 1;
    position: absolute;
    bottom: 30px;
    right: 40px;
    cursor: pointer;
    background: rgba(35, 31, 32, 0.4);
    border-radius: 4px;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__video {
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: fill;
    border-radius: 15px;

    &::-webkit-media-controls {
      display: none !important;
    }
  }

  .Button {
    position: absolute;
    bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      padding: 15px 37px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(35, 31, 32, 0.4);
      border-radius: 4px;
      border: none;
      width: auto;
      height: 4.8rem;

      img {
        margin-right: 17px;
      }
    }
  }

  @include media-screen-md {

    &__container {
      text-align: start;
      border-radius: 0;
      box-shadow: none;
    }

    &__video {
      border-radius: 0;
    }
  }
}

.mobile-casting {
  position: absolute;
  text-align: start;
  border-radius: 0;
  box-shadow: none;

  video {
    border-radius: 0;
  }

  @media screen and (orientation: portrait) {
    width: 100vh;
    height: 100vw;
    transform: rotate(90deg);
    transform-origin: center;
  }

  @media screen and (orientation: landscape) {
    min-width: 100%;
    min-height: 100%;
  }

  &.ios-casting {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (orientation: portrait) {
      min-width: 100vh;
      min-height: 100vw;
    }

    video {
      position: relative;
      width: auto;
      height: auto;
      min-width: auto;
      min-height: auto;
      border-radius: 0;
    }

    .Button {

      button {
        padding: 0;
      }
    }
  }
}
