@import './public/assets/scss/media-mixins';

.AuthHeader {
  margin-bottom: 70px;
  display: flex;
  justify-content: space-between;

  font-family: 'RobotoLight', sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #222222;

  &.mobile {
    @media screen and (orientation: landscape) {
      margin-bottom: 20px;
    }
  }

  &__logo {
    height: 35px;
    cursor: pointer;
  }

  &__area {
    display: flex;
    align-items: center;
    position: relative;

    &-change {
      margin-left: 16px;
      cursor: pointer;
    }

    &-icon {
      margin-right: 15px;
      cursor: pointer;
    }

    &-name {
      cursor: pointer;
    }

    .dropdown-overlay {
      top: 0;
      left: 0;
      position: fixed;
      z-index: 999;
      width: 100vw;
      height: 100vh;
      display: none;

      &.show {
        display: block;
      }
    }
  }

  @include media-screen-md {
    &__logo {
      height: 20px;
    }

    &__area {
      &-icon {
        margin-right: 8px;
      }

      &-change {
        margin-left: 14px;
      }
    }
  }
}
