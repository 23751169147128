@import './public/assets/scss/media-mixins';

@mixin auth-container-medium-screen {
    padding: 24px 16px;

    &__main {
        display: flex;
        align-items: center;
        flex-grow: 2;

        &__info {
            width: 100%;
        }

        &__illustration {
            display: none;
        }
    }
}

.AuthContainer {
    background: url(../../public/assets/images/BG.png) 0 0/cover;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__content {
        width: 75%;
        min-height: 60%;
        max-width: 1000px;
        background: #FFFFFF;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        padding: 60px 60px 90px;

        &.mobile {
            @include auth-container-medium-screen();

            @media screen and (orientation: landscape) {
                position: absolute;
                top: 5px;
                bottom: 5px;
                overflow-y: scroll;
            }
        }

        &__main {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-grow: 2;

            &__illustration {
                width: 50%;
                max-width: 50%;
                display: flex;
                justify-content: center;

                img {
                    align-self: flex-start;
                    max-width: 80%;
                    width: 80%;
                }
            }
        }

        @include media-screen-md {
            @include auth-container-medium-screen();
        }
    }

}
