@import '../../../public/assets/scss/media-mixins';

.AuthLoading {
  display: flex;
  flex-direction: column;
  width: 40%;
  text-align: center;
  font-family: 'RobotoLight', sans-serif;
  font-weight: 300;
  color: #222222;

  .LoaderSpinner {
    &:before, &:after {
      background: #FFFFFF;
    }
  }

  &__description {
    font-size: 20px;
    line-height: 30px;
  }

  @include media-screen-md {
    width: 100%;
  }
}


