@import './public/assets/scss/media-mixins';

.ErrorsViewContainer {
  background: #E5E5E5;
  height: 100%;

  display: flex;
  flex-direction: column;


  &__error-view {
    position: relative;
    flex-grow: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include media-screen-md {
    @media screen and (orientation: landscape) {
      overflow-y: scroll;
    }

    .Button {
      width: 200px;

      button {
        span {
          font-size: 1.4rem;
          line-height: 1.6rem;
        }
      }
    }
  }
}
