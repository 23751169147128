@import '../../../../public/assets/scss/media-mixins';

.TextInput {
    position: relative;
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;
    padding-bottom: 2rem;
}

label {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1 1 100%;
    flex-direction: column;
    margin-top: 1.2rem;

    &.invalid {
        .input {
            border-bottom: 0.1rem solid #FF595E;
        }

        .icon-invalid {
            display: block;
        }

        .icon-valid {
            display: none;
        }
    }

    &.active {
        .label {
            font-family: RobotoMedium, sans-serif;
            color: #141414;
            font-size: 12px;
            bottom: 3.8rem;
            opacity: 1;
        }
    }

    &.disabled {
        opacity: 0.5;
    }

    .icon {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 2.4rem;
        height: inherit;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .label {
        font-family: RobotoLight, sans-serif;
        color: #141414;
        font-size: 2rem;
        font-weight: 300;
        line-height: 2.4rem;
        position: absolute;
        bottom: 1.2rem;
        left: 3rem;
        flex: 1 1 100%;
        transition: 0.2s;
        letter-spacing: 0;
        opacity: 0.5;
    }

    .input {
        font-family: RobotoLight, sans-serif;
        font-size: 1.6rem;
        font-weight: 300;
        line-height: 1.9rem;
        box-sizing: border-box;
        width: 100%;
        padding: 1.2rem 3rem;
        transition: 0.2s ease-in-out;
        letter-spacing: 0;
        color: #222222;
        border: none;
        border-bottom: 0.1rem solid #BBBBBB;
        outline: none;
        background: none;

        &:focus {
            border-color: #23B9FF;
        }

        &:focus + .label {
            font-family: RobotoMedium, sans-serif;
            font-size: 1.2rem;
            bottom: 3.8rem;
            color: #23B9FF;
        }
    }

    .validation-error {
        text-align: left;
        font-family: GellixLight, sans-serif;
        font-size: 1.4rem;
        font-weight: 400;
        color: #FF595E;
        position: absolute;
        top: 5rem;
        left: 0;
    }

    @include media-screen-md {
        .label {
            font-size: 1.4rem;
            line-height: 1.6rem;
        }

        .input {
            font-size: 1.4rem;
            line-height: 1.6rem;
        }

        .validation-error {
            top: 4.5rem;
        }
    }
}
