$screen-sm: 480px;
$screen-md: 768px;
$screen-lg: 1024px;
$screen-xl: 1025px;
// Small devices
@mixin media-screen-sm {
  @media all and (max-width: #{$screen-sm}) {
    @content;
  }
}

// Medium devices
@mixin media-screen-md {
  @media all and (max-width: #{$screen-md}) {
    @content;
  }
}

// Large devices
@mixin media-screen-lg {
  @media all and (max-width: #{$screen-lg}) {
    @content;
  }
}

// Extra large devices
@mixin media-screen-xl {
  @media all and (min-width: #{$screen-xl}) {
    @content;
  }
}
