@import './public/assets/scss/media-mixins';

.ErrorBoundaryContainer {
  background: url(../../../public/assets/images/BG.png) 0 0/cover;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.ErrorBoundary {
  width: 75%;
  max-width: 1000px;
  height: 60%;
  background: #FFFFFF;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 60px;
  display: flex;
  flex-direction: column;

  &__header {
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;

    font-family: 'RobotoLight', sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: #222222;

    &-logo {
      height: 35px;
    }

    &-area {
      display: flex;
      align-items: center;
      position: relative;

      &-change {
        margin-left: 16px;
        cursor: pointer;
      }

      &-icon {
        margin-right: 15px;
        cursor: pointer;
      }

      &-name {
        cursor: pointer;
      }

      .dropdown-overlay {
        top: 0;
        left: 0;
        position: fixed;
        z-index: 999;
        width: 100vw;
        height: 100vh;
        display: none;

        &.show {
          display: block;
        }
      }
    }
  }

  &__main {
    display: flex;
    align-items: center;
    flex-grow: 2;

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 50%;
      text-align: center;
      font-family: 'RobotoLight', sans-serif;
      font-weight: 300;
      color: #231F20;

      &__illustration {
        width: 100px;
        height: 100px;
        margin: 0 auto 16px;
      }

      &-title {
        font-weight: 400;
        font-size: 28px;
        line-height: 33px;
        margin-bottom: 16px;
      }

      &-description {
        font-size: 20px;
        line-height: 23px;
      }
    }

    &__illustration {
      width: 50%;
      display: flex;
      justify-content: center;

      img {
        width: 80%;
      }
    }
  }

  @include media-screen-md {
    padding: 24px 16px;

    &__header {
      &-logo {
        height: 20px;
      }

      &-area {
        &-icon {
          margin-right: 8px;
        }

        &-change {
          margin-left: 14px;
        }
      }
    }

    &__main {
      display: flex;
      align-items: center;
      flex-grow: 2;

      &__info {
        width: 100%;
      }

      &__illustration {
        display: none;
      }
    }
  }
}



