@import './public/assets/scss/media-mixins';

.CastingStatusNotConnected {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  &__image {
    width: 140px;
    height: 140px;
  }

  &__header {
    font-family: RobotoLight, sans-serif;
    font-weight: 400;
    font-size: 28px;
    line-height: 33px;
    margin-top: 40px;
  }

  &__description {
    font-family: RobotoLight, sans-serif;
    font-weight: 300;
    font-size: 20px;
    line-height: 23px;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  &__searching {
    display: flex;
    align-items: center;
    padding: 12px 20px 12px 35px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 20px;


    &__text {
      margin-left: 35px;
      font-family: 'RobotoLight', sans-serif;
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #222222;
    }

  }

  @include media-screen-md {
    &__header {
      font-size: 20px;
      line-height: 26px;
    }

    &__description {
      font-size: 14px;
      line-height: 20px;
      margin: 16px auto;
    }

    .LoaderDots {
      margin: 16px auto;
    }

    &__searching-text {
      font-size: 16px;
      line-height: 19px;
    }
  }
}
