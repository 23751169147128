@import './public/assets/scss/media-mixins';

.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

    &__logo {
        height: 38px;
    }

    &__info {
        position: relative;
        font-family: 'RobotoRegular', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #222222;
        display: flex;
        align-items: center;
        cursor: pointer;

        &__image {
            width: 41px;
            height: 41px;
            margin-left: 30px;
        }

        .dropdown {
            padding: 0;
            margin: 0;
            top: calc(100% + 20px);
            right: 10px;
            width: 180px;

            .menu-items {
                padding: 10px 10px 10px 16px;
                margin: 0;
                font-family: 'RobotoLight', sans-serif;
                font-weight: 300;
                font-size: 16px;
                line-height: 19px;
                color: #222222;

                &:after {
                    content: "";
                    position: absolute;
                    width: calc(100% - 12px);
                    left: 6px;
                    bottom: 0;
                    border-bottom: 1px solid #CCCCCC;
                }

                &:hover {
                    background: #E9F8FF;
                }

                &:first-child {
                    border-radius: 0.5rem 0.5rem 0 0;
                    -webkit-box-shadow: inset 0 6px 4px -4px rgb(0 0 0 / 30%);
                    -moz-box-shadow: inset 0 6px 4px -4px rgb(0 0 0 / 30%);
                    box-shadow: inset 0 6px 4px -4px rgb(0 0 0 / 30%);
                }

                &:last-child {
                    border-radius: 0 0 0.5rem 0.5rem;

                    &:after {
                        content: none;
                    }
                }
            }
        }

        .dropdown-overlay {
            top: 0;
            left: 0;
            position: fixed;
            z-index: 999;
            width: 100vw;
            height: 100vh;
            display: none;

            &.show {
                display: block;
            }
        }
    }

    @include media-screen-md {

        &__logo {
            height: 16px;
        }

        &__info {
            font-size: 14px;
            line-height: 16px;

            &__image {
                width: 24px;
                height: 24px;
                margin-left: 16px;
            }
        }
    }
}

