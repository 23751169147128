@import './public/assets/scss/media-mixins';

.ForgotPassword {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 40%;
  text-align: center;
  font-family: 'RobotoLight', sans-serif;
  font-weight: 300;
  color: #222222;


  &__title {
    font-size: 40px;
    line-height: 54px;
  }

  &__description {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  &__hint {
    margin-top: 25px;
    text-align: left;
    font-family: 'RobotoLight', sans-serif;
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: #222222;
  }

  .Button {
    width: 60%;
    align-self: center;
    flex-grow: 2;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  @include media-screen-md {
    width: 100%;
  }
}



