.Button {
  width: 100%;

  &.disable {
    pointer-events: none;

    button {
      opacity: 0.5;
    }
  }

  button {
    width: 100%;
    height: 4rem;
    cursor: pointer;
    background-color: #00BAFF;
    border: 1px solid #00BAFF;
    border-radius: 3rem;
    outline: none;

    font-family: 'RobotoRegular', sans-serif;
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.4rem;
    text-align: center;
    letter-spacing: 0.73px;
    text-transform: uppercase;

    color: #FFFFFF;
  }
}
