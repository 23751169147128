.SnackbarNotification {
  position: fixed;
  bottom: 24px;
  right: 24px;
  min-width: 310px;
  background: white;
  border-left: 5px solid #23B9FF;
  border-radius: 3px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);

  display: none;
  flex-direction: column;
  padding: 13px;

  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;

  &.show {
    display: flex;
  }

  &.success {
    border-color: #00DE94;
  }

  &.info {
    border-color: #23B9FF;
  }

  &.warning {
    border-color: #FCA94B;
  }

  &.error {
    border-color: #FF595E;
  }

  &__main {
    display: flex;
    justify-content: space-between;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 19px 0;

    img {
      width: 32px;
      height: 32px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 2;
    margin: 0 15px;

    line-height: 16px;
    color: #000000;

    &__title {
      margin-bottom: 10px;
      font-family: 'RobotoRegular', sans-serif;
      font-weight: 400;
      font-size: 16px;
    }

    &__description {
      font-family: 'RobotoLight', sans-serif;
      font-weight: 300;
      font-size: 14px;
      white-space: pre-line;
    }
  }

  &__close {
    img {
      cursor: pointer;
    }
  }

  /* Animations to fade the snackbar in and out */
  @-webkit-keyframes fadein {
    from {right: -310px;}
    to {right: 24px;}
  }

  @keyframes fadein {
    from {right: -310px;}
    to {right: 24px;}
  }
}
