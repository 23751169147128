@import './public/assets/scss/media-mixins';

.CastingStatusConnecting {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'RobotoLight', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 3.6rem;
  line-height: 4.2rem;
  text-align: center;
  color: #231F20;

  &__description {
    margin: 23px auto;
  }

  @include media-screen-md {
    font-size: 20px;
    line-height: 26px;
  }
}
