@font-face {
  font-family: RobotoLight;
  src: url('../fonts/roboto/Roboto-Light.ttf');
}

@font-face {
  font-family: RobotoRegular;
  src: url('../fonts/roboto/Roboto-Regular.ttf');
}

@font-face {
  font-family: RobotoMedium;
  src: url('../fonts/roboto/Roboto-Medium.ttf');
}

@font-face {
  font-family: GellixThin;
  src: url('../fonts/gellix/Gellix-Thin.ttf');
}

@font-face {
  font-family: GellixLight;
  src: url('../fonts/gellix/Gellix-Regular.ttf');
}

@font-face {
  font-family: GellixRegular;
  src: url('../fonts/gellix/Gellix-Regular.ttf');
}

@font-face {
  font-family: GellixMedium;
  src: url('../fonts/gellix/Gellix-SemiBold.ttf');
}

@font-face {
  font-family: GellixSemiBold;
  src: url('../fonts/gellix/Gellix-SemiBold.ttf');
}

@font-face {
  font-family: GellixBold;
  src: url('../fonts/gellix/Gellix-Bold.ttf');
}

$main-font-size: 16px; /* use as main font-size and declared in <html> tag, all other font-size should based on this and use rem, */
$button-font-size: 18px; /* use as default font-size for all buttons */
$title-font-size: 40px; /* use as default font-size for all titles of page's headers */
