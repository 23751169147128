.dropdown {
  width: 100%;
  position: absolute;
  right: 0;
  top: 100%;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
  0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
}

.dropdown.show {
  display: block;
}

.menu-items {
  position: relative;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px auto;
  padding-left: 16px;
  height: 40px;
  cursor: pointer;
}

.menu-items img {
  margin-right: 10px;
}

.menu-items span {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

.menu-items a,
.menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}

.menu-items:hover{
  background-color: #f2f2f2;
}
