@import './public/assets/scss/media-mixins';

.Restricted {
  background: #FFFFFF;
  height: 100%;

  display: flex;
  flex-direction: column;

  @include media-screen-md {
    @media screen and (orientation: landscape) {
      overflow-y: scroll;
    }
  }

  &:has(.CastingStatusNotConnected) {
    background: #EFEFF0;
  }
}

.confirmIdleElement {
  display: flex;

  .Button {
    display: flex;
    margin-top: 20px;

    button {
      font-size: 14px;
      line-height: 16px;
      padding: 0 16px;
    }

    &:first-child {
      width: 45%;
      margin-right: 8px;

      button {
        color: #00BAFF;
        background-color: #FFF;
      }

    }
  }
}
