@import './public/assets/scss/media-mixins';

@mixin forgot-password-send-email-middle-screen {

  .ForgotPasswordSendEmail__title {
    font-size: 2.8rem;
    line-height: 3.4rem;
  }

  .ForgotPasswordSendEmail__description {
    font-size: 1.6rem;
    line-height: 1.9rem;
  }

  .ForgotPasswordSendEmail__hint {
    margin-top: 0;
  }

  .TextInput {
    padding-bottom: 4rem;
  }

  .Button {
    width: 224px;
  }
}

.ForgotPasswordSendEmail {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  width: 40%;
  text-align: center;
  font-family: 'RobotoLight', sans-serif;
  font-weight: 300;
  color: #222222;

  &.mobile {
    @media screen and (orientation: landscape) {
      @include forgot-password-send-email-middle-screen;
    }

    .Button {
      width: 224px;
    }
  }

  &__title {
    font-size: 40px;
    line-height: 54px;
  }

  &__description {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  &__hint {
    margin-top: 25px;
    text-align: left;
    font-family: 'RobotoLight', sans-serif;
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: #222222;
  }

  .Button {
    width: 60%;
    align-self: center;
    flex-grow: 2;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  @include media-screen-md {
    @include forgot-password-send-email-middle-screen;
    width: 100%;
  }
}


